<template>
  <div class="flex w-full">
    <div
      v-if="item.submenu"
      :class="{'translate-x-full' : !subMenuOpen}"
      class="fixed top-0 mt-36 h-[calc(100%-205px)] w-full overflow-y-auto bg-white transition duration-500"
    >
      <button
        class="mobile-menu-item w-full !border-t-0 py-4 pl-5"
        @click="subMenuOpen = false"
      >
        <div class="flex items-center gap-4">
          <IconsArrowRight class="mr-1 w-3 rotate-180 text-primary" />
          <UiIcon
            :name="item.icon"
            small
          />
          {{ item.label }}
        </div>
      </button>
      <div
        v-for="(subItem, subIndex) in item.submenu.items"
        :key="subIndex"
        class="mobile-menu-item"
      >
        <NuxtLink :to="subItem.link">
          <div class="flex grow items-center gap-4">
            {{ subItem.label }}
          </div>
          <div class="sub-icon">
            <IconsAngleRight />
          </div>
        </NuxtLink>
      </div>
    </div>
    <NuxtLink
      :class="[
        item.submenu ? 'flex justify-between' : 'items-center',
        item.highlight ? 'bg-secondary' : 'bg-white'
      ]"
      :to="item.link"
    >
      <div class="flex grow items-center gap-4">
        <UiIcon
          :name="item.icon"
          small
        />
        {{ item.label }}
      </div>
      <div
        v-if="!item.submenu"
        class="px-7 text-primary-50"
      >
        <IconsAngleRight :class="{'text-white' : item.highlight}" />
      </div>
    </NuxtLink>
    <button
      v-if="item.submenu"
      class="px-7 text-primary-50"
      @click="subMenuOpen = true"
    >
      <IconsArrowRight class="w-3 !text-primary" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import type {MenuItemMobile} from '~/types/menu-item-mobile';

defineProps<{
  item: MenuItemMobile,
}>();

const subMenuOpen = ref(false);
</script>
