<template>
  <NuxtLink
    :to="to"
    class="fuzzy flex items-center px-4 py-2.5 text-sm no-underline lg:text-base"
    :class="{'active-link': isActive}"
    :target="target"
  >
    <slot />
    <div class="ml-2">
      {{ name }}
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type {RouteLocationRaw} from 'vue-router';

const props = defineProps<{
  name: string
  target?: string | undefined
  to: string | RouteLocationRaw | { name: string }
}>();

const route = useRoute();

const isActive = ref(false);

onMounted(() => {
  checkIfPageIsActive(route.path);
});

watch(() => route.path, (newValue) => {
  checkIfPageIsActive(newValue);
});

function checkIfPageIsActive(path) {
  const pathSegments = path.split('/');

  isActive.value = (props.to?.name ?? props.to) === pathSegments[1];
}
</script>

<style scoped>
/* noinspection CssUnusedSymbol */
.active-svg {
  @apply text-secondary;
}

a {
  @apply transition font-bold border-l-4 border-white;
}

/* noinspection CssUnusedSymbol */
.active-link, a:hover {
  @apply border-l-secondary;
}

a:hover,
a.router-link-exact-active ::slotted(svg),
a.router-link-active.fuzzy ::slotted(svg) {
  @apply active-svg;
}

/* noinspection CssUnusedSymbol */
a.router-link-exact-active,
a.router-link-active.fuzzy {
  @apply active-link;
}
</style>
