<template>
  <div
    class="fixed inset-0 z-50 h-screen -translate-y-full bg-white px-5 pb-16 pt-20 transition duration-500 lg:hidden"
  >
    <FormKit
      :actions="false"
      type="form"
    >
      <div class="relative">
        <IconsSearch class="absolute left-5 top-1/2 size-4 -translate-y-1/2" />
        <FormKit
          input-class="bg-black-200 placeholder:text-primary-900 pl-12 border-px hover:border-transparent"
          placeholder="Waar bent u naar op zoek?"
          type="search"
        />
      </div>
    </FormKit>
    <div class="-mx-5 mt-5 h-full overflow-y-auto pb-16">
      <div
        v-for="(item, index) in menuItemsMobile"
        :key="index"
        :class="{'!border-0 text-white' : item.highlight}"
        class="mobile-menu-item"
      >
        <UiMenuMobileItem :item="item" />
      </div>
      <button
        class="mobile-menu-item fixed bottom-0 w-full !justify-center gap-4 bg-primary-50 py-5"
        :class="user ? 'bg-primary-50' : 'bg-secondary text-white'"
        @click="signOut"
      >
        <template v-if="user">
          <IconsLogout class="w-4" />
          Uitloggen
        </template>
        <template v-else>
          Inloggen
          <IconsUser class="w-4" />
        </template>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {MenuItemMobile} from '~/types/menu-item-mobile';

defineProps<{
  menuItemsMobile: MenuItemMobile,
}>();

const user = useSofieUserWhenAuthenticated();

const {signOut} = useSession();
</script>
